<template>
  <v-btn
    color="primary"
    @click="create"
    class="white--text"
    :disabled="disabled ? true : false"
  >
    <v-icon>{{ icon }}</v-icon>
    {{ title }}
  </v-btn>
</template>

<script>
export default {
  name: "AddButton",
  props: {
    createFunction: { type: Function, required: true },
    icon: { type: String, required: true },
    title: { type: String, required: true },
    disabled: { type: Boolean, required: false },
  },

  data() {
    return {
      snackbar: {},
    };
  },

  methods: {
    create() {
      this.createFunction();
    },
  },

  created() {},
};
</script>
